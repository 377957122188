<template>
<div >
  <div style="margin: 20px 0 0 20px">
    <MyBreadcrumb></MyBreadcrumb>
  </div>
  <div class="service">
    <div>
      <Button type="primary" size="large" style="margin-right: 20px" @click="showModal">区域负责人管理</Button>
      <Button size="large" @click="goUser">负责人信息设置</Button>
      <Button size="large" type="primary" style="margin-left: 20px;" @click="regionalFollowUp()">区域跟进</Button>
    </div>
    <div class="search">
      <div class="search-item">
        <div class="name">覆盖日期筛选：</div>
        <div class="cont">
          <DatePicker :value="date" size="large" format="yyyy/MM/dd" type="daterange" placement="bottom-end" placeholder="请选择时间" style="width: 250px" @on-change="changeDate"></DatePicker>
        </div>
      </div>
      <div class="search-item">
        <div class="name">负责人筛选：</div>
        <div class="cont">
          <Select v-model="customerUserId" placeholder="请选择角色" @on-change="selectUser" clearable size="large" style="width: 200px">
            <Option :value="item.user_id" v-for="item in customerUserList" :key="item.user_id">{{ item.user.nickname }}</Option>
          </Select>
        </div>
      </div>
    </div>
    <Table :row-class-name="rowClassName" border :columns="columns" :loading="loading" :data="data"></Table>
  </div>
  <!-- <Modal
      v-model="modal"
      title="设置区域负责人"
      width="1100px"
  >
      <div class="set-area-user">
        <div class="set-area-user-item" v-for="item in modalCityList" :key="item.id">
          <div class="set-area-user-item-name">{{item.name}}</div>
          <Select v-model="item.customer_service_user_id" placeholder="请选择负责人" clearable size="large" style="width: 200px">
            <Option :value="item.user_id" v-for="item in customerUserList" :key="item.user_id">{{ item.user.nickname }}</Option>
          </Select>
        </div>
      </div>
      <div slot="footer">
        <Button @click="closeModal">取消</Button>
        <Button type="primary" @click="confirmSet">确定</Button>
      </div>
  </Modal> -->
  <!-- <Modal
      v-model="addDingGroupModal"
      title="添加/编辑钉钉群"
      width="1100px"
  >
    <el-form ref="formValidate" :model="formValidate" :rules="ruleValidate" label-width="100px">
      <el-form-item label="群二维码" prop="uploadfileId">
        <el-upload :action="constant.URL + '/uploadfile/upload'"
                   :data="{
                      type:3
                    }"
                   accept=".jpg,.png,.jpeg,.gif"
                   :headers="{
                      'access-token':Cookies.get('liteAccessToken')
                    }"
                   :on-success="uploadFileSuccess"
                   :show-file-list="false"
        >
          <div class="upload-btn" v-if="!uploadImg">
            <Icon type="ios-add" :size="50" />
          </div>
          <img :src="uploadImg" width="120" height="120" v-else />
        </el-upload>
      </el-form-item>
      <el-form-item label="群号" prop="num">
        <el-input v-model="formValidate.num" size="large" placeholder="请输入群号" class="w300"></el-input>
      </el-form-item>
      <el-form-item label="群过期时间" prop="date">
        <el-date-picker
            v-model="formValidate.date"
            type="date"
            style="width:300px"
            placeholder="选择日期"
            :picker-options="dateOptions"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <Button @click="closeAddDingModal">取消</Button>
      <Button type="primary" @click="confirmAddDing">确定</Button>
    </div>
  </Modal> -->
  <!-- 设置区域负责人抽屉 -->
  <el-drawer :size="1200" :visible.sync="modal">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">设置区域负责人</div>
      <div class="drawer-title-btns">
        <Button @click="closeModal" style="margin-right: 10px;">取消</Button>
        <Button type="primary" @click="confirmSet">确定</Button>
      </div>
    </div>
    <div style="padding: 20px;">
      <div class="set-area-user">
        <div class="set-area-user-item" v-for="item in modalCityList" :key="item.id">
          <div class="set-area-user-item-name">{{item.name}}</div>
          <Select v-model="item.customer_service_user_id" placeholder="请选择负责人" clearable size="large" style="width: 200px">
            <Option :value="item.user_id" v-for="item in customerUserList" :key="item.user_id">{{ item.user.nickname }}</Option>
          </Select>
        </div>
      </div>
    </div>
  </el-drawer>
  <!-- 添加\编辑钉钉群 -->
  <el-drawer :size="800" :visible.sync="addDingGroupModal">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">设置区域负责人</div>
      <div class="drawer-title-btns">
        <Button @click="closeAddDingModal" style="margin-right: 10px;">取消</Button>
        <Button type="primary" @click="confirmAddDing">确定</Button>
      </div>
    </div>
    <div style="padding: 20px;">
      <el-form ref="formValidate" :model="formValidate" :rules="ruleValidate" label-width="100px">
        <el-form-item label="群二维码" prop="uploadfileId">
          <el-upload :action="constant.URL + '/uploadfile/upload'"
                    :data="{
                        type:3
                      }"
                    accept=".jpg,.png,.jpeg,.gif"
                    :headers="{
                        'access-token':Cookies.get(constant.tokenName)
                      }"
                    :on-success="uploadFileSuccess"
                    :show-file-list="false"
          >
            <div class="upload-btn" v-if="!uploadImg">
              <Icon type="ios-add" :size="50" />
            </div>
            <img :src="uploadImg" width="120" height="120" v-else />
          </el-upload>
        </el-form-item>
        <el-form-item label="群号" prop="num">
          <el-input v-model="formValidate.num" size="large" placeholder="请输入群号" class="w300"></el-input>
        </el-form-item>
        <el-form-item label="群过期时间" prop="date">
          <el-date-picker
              v-model="formValidate.date"
              type="date"
              style="width:300px"
              placeholder="选择日期"
              :picker-options="dateOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
  </el-drawer>

  <el-drawer :visible.sync='followupShow' :size="800">
    <div class='drawer-title' slot='title'>
      <div class='drawer-title-text'>跟进</div>
      <div class='drawer-title-btns'>
        <el-button size="small" type="primary" @click="followUpSave()">确定</el-button>
      </div>
    </div>
    <div style='padding:20px;'>
      <p class="form-title">跟进内容：</p>
      <el-input v-model="followForm.content" type="textarea" placeholder="请输入备注内容"></el-input>
    </div>
  </el-drawer>

  <RegionalFollowUp :show="areaFollowShow" :provinceId="provinceId" @close="areaFollowClose()"/>

</div>
</template>

<script>
import Cookies from "js-cookie";
import util from '@/utils/tools.js';
import RegionalFollowUp from "./components/regionalFollowUp.vue";
export default {
  name: "serviceView",
  components:{
    RegionalFollowUp
  },
  data(){
    const validateUpload = (rule, value, callback)=>{
      this.$nextTick(()=>{
        console.log(this.uploadImg,'this.uploadImgthis.uploadImg')
        if (!this.uploadImg) {
          return callback(new Error('请上传群二维码'));
        }else{
          callback();
        }
      })
    }
    return{
      areaFollowShow:false,
      Cookies:Cookies,
      followupShow:false,
      followForm:{
        follow_site_id:"",
        follow_user_id:"",
        content:""
      },
      columns: [
        {
          title: '省份',
          key: 'id',
          minWidth:130,
          render: (h, para) => {
            return util.tableColumnTooltip(h,para.row?.place?.name);
          }
        },
        {
          title: '负责人',
          key: 'name',
          minWidth:100,
          render: (h, para) => {
            return h('span', para.row.user && para.row.user.nickname);
          }
        },
        {
          title: '院校数量',
          key: 'site_count',
          minWidth:100,
          // renderHeader: (h, params) => {
          //   return h('div',[
          //     h('span', '院校数量(' ),
          //     h('span', {
          //       style: {
          //         color:'#ed4014'
          //       },
          //     }, this.totalData.total_site_count ),
          //     h('span', ')' ),
          //   ]);
          // }
        },
        {
          title: '已覆盖院校',
          key: 'have_user_site_count',
          minWidth:100,
          // renderHeader: (h, params) => {
          //   return h('div',[
          //     h('span', '已覆盖院校(' ),
          //     h('span', {
          //       style: {
          //         color:'#ed4014'
          //       },
          //     }, this.totalData.total_have_user_site_count ),
          //     h('span', ')' ),
          //   ]);
          // }
        },
        {
          title: '覆盖度',
          key: 'have_user_site_rate',
          minWidth:100,
          render: (h, para) => {
            return h('span', para.row.have_user_site_rate + '%');
          },
          renderHeader: (h, params) => {
            return h('div',[
              h('span', '覆盖度' ),
              // h('span', {
              //   style: {
              //     color:'#ed4014'
              //   },
              // }, this.totalData.avg_have_user_site_rate + '%' ),
              // h('span', ')' ),
              h('Tooltip', {
                props: {
                  content: "覆盖度 = 已覆盖院校/院校数量",
                },
              }, [
                h('Icon', {
                  style: {
                    marginLeft: '3px',
                    marginTop: '1px',
                    verticalAlign: 'top'
                  },
                  props: {
                    type: 'ios-help-circle-outline',
                    size: 16
                  },
                }),

              ])
            ]);
          }
        },
        {
          title: '老师数量',
          key: 'teacher_count',
          minWidth:100,
          // renderHeader: (h, params) => {
          //   return h('div',[
          //     h('span', '老师数量(' ),
          //     h('span', {
          //       style: {
          //         color:'#ed4014'
          //       },
          //     }, this.totalData.total_teacher_count ),
          //     h('span', ')' ),
          //   ]);
          // }
        },
        {
          title: '学生数量',
          key: 'student_count',
          minWidth:100,
          // renderHeader: (h, params) => {
          //   return h('div',[
          //     h('span', '学生数量(' ),
          //     h('span', {
          //       style: {
          //         color:'#ed4014'
          //       },
          //     }, this.totalData.total_student_count ),
          //     h('span', ')' ),
          //   ]);
          // }
        },
        {
          title: '刷题总数',
          key: 'train_count',
          minWidth:100,
          // renderHeader: (h, params) => {
          //   return h('div',[
          //     h('span', '刷题总数(' ),
          //     h('span', {
          //       style: {
          //         color:'#ed4014'
          //       },
          //     }, this.totalData.total_train_count ),
          //     h('span', ')' ),
          //   ]);
          // }
        },
        {
          title: '题库已激活数量',
          key: 'actived_category_count',
          minWidth:130,
          // renderHeader: (h, params) => {
          //   return h('div',[
          //     h('span', '题库已激活数量(' ),
          //     h('span', {
          //       style: {
          //         color:'#ed4014'
          //       },
          //     }, this.totalData.total_actived_category_count ),
          //     h('span', ')' ),
          //   ]);
          // }
        },
        {
          title: '题库采用率',
          key: 'actived_category_use_rate',
          minWidth:120,
          render: (h, para) => {
            return h('span', para.row.actived_category_use_rate + '%');
          },
          renderHeader: (h, params) => {
            return h('div',[
              h('span', '题库采用率' ),
              // h('span', {
              //   style: {
              //     color:'#ed4014'
              //   },
              // }, this.totalData.avg_actived_category_use_rate + '%' ),
              // h('span', ')' ),
              h('Tooltip', {
                // props: {
                //   content: "题库采用率=题库激活数量/有刷题数据的题库数量",
                // },
                // style:{
                //   wordWrap:'break-word',
                //   wordBreak: 'normal',
                //
                // },
              }, [
                h('Icon', {
                  style: {
                    marginLeft: '3px',
                    marginTop: '1px',
                    verticalAlign: 'top'
                  },
                  props: {
                    type: 'ios-help-circle-outline',
                    size: 16
                  },
                }),
                h('div', {
                  style: {
                    wordWrap:'break-word',
                    wordBreak: 'normal',
                  },
                  slot:'content',
                },[h('p', '题库采用率 = 题库激活数量/有刷题数据的'),
                  h('p', '题库数量')]),
              ])
            ]);
          }
        },
        {
          title: '群过期时间',
          minWidth:100,
          render: (h, para) => {
            return h('span', para.row.area_info.dingding_qrcode_expire >0 ?util.timeFormatter(
                new Date(+para.row.area_info.dingding_qrcode_expire * 1000),
                'yyyy-MM-dd'
            ):para.row.id!=="合计"?'-':'');
          }
        },
        {
          title:"A/B/C",
          key:"level_count",
          minWidth:80,
          render:(h,params)=>{
            return h('span',`${params.row.site_level_a_count}/${params.row.site_level_b_count}/${params.row.site_level_c_count}`)
          }
        },
        {
          title: '操作',
          width: 320,
          fixed:"right",
          render: (h, params) => {
            return params.row.id!=="合计"? h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginTop: '5px',
                  marginRight: '5px',
                  // color:'#2d8cf0'
                },
                on: {
                  click: () => {
                    this.goDetail(params.row)
                  }
                }
              }, '院校列表' ),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginTop: '5px',
                  marginRight: '5px',
                  // color:'#2d8cf0'
                },
                on: {
                  click: () => {
                    this.addDingGroup(params.row)
                  }
                }
              }, '添加钉钉群' ),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginTop: '5px',
                  marginRight: '5px',
                  // color:'#2d8cf0'
                },
                on: {
                  click: () => {
                    this.exportTeacher(params.row)
                  }
                }
              }, '导出老师' ),
              params?.row?.user?.nickname?h("Button",{
                props:{
                  type:"primary",
                  size:"small"
                },
                style:{
                  marginTop:"5px",
                },
                on:{
                  click:()=>{
                    this.provinceId=params.row.place.id;
                    this.areaFollowShow=true;
                  }
                }
              },"区域跟进"):''
              // h("Button",{
              //   props:{
              //     type:"primary",
              //     size:"small"
              //   },
              //   style:{
              //     marginTop:"5px",
              //     marginRight: '5px',
              //   },
              //   on:{
              //     click:()=>{
              //       this.followUp(params.row);
              //     }
              //   }
              // },"跟进")
            ]):"";
          }
        }
      ],
      loading:false,
      data:[],
      total:0,
      page:1,
      pageSize:100,
      date:[],
      customerUserId:'',
      customerUserList:[],
      cityList:[],
      modal:false,
      modalCityList:[],
      curData:{},
      formValidate: {
        num: '',
        date: '',
        uploadfileId: '',
      },
      ruleValidate: {
        num: [
          { required: true, message: '请输入群号', trigger: 'blur' }
        ],
        date: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
        uploadfileId: [
          { required: true, message: '请上传联系二维码',validator: validateUpload, }
        ]
      },
      uploadImg:'',
      addDingGroupModal:false,
      dateOptions:{
        disabledDate (date) {
          return date && date.valueOf() < Date.now();
        }
      },
      totalData:{},
      provinceId:""
    }
  },
  created(){
    this.getCustomerList();
    this.getPlaceList();
    this.getList();
  },
  methods:{
    // 合计行颜色
    rowClassName (row, index) {
        if (index === 0) {
          return "row1Style";
        }
    },
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        start_time:new Date(this.date[0]).getTime()/1000,
        end_time:new Date(this.date[1]).getTime()/1000,
        customer_user_id:this.customerUserId
      }
      this.loading = true;
      this.api.customer.statByProvince(params).then((res)=>{
        this.loading = false;
        this.data = res.list;
        this.totalData = res.total_data;
        const obj={
          id:"合计",
          place:{
            name:"合计"
          },
          user:{
            nickname:""
          },
          site_count:this.totalData.total_site_count,
          have_user_site_count:this.totalData.total_have_user_site_count,
          have_user_site_rate:this.totalData.avg_have_user_site_rate,
          teacher_count:this.totalData.total_teacher_count,
          student_count:this.totalData.total_student_count,
          train_count:this.totalData.total_train_count,
          actived_category_count:this.totalData.total_actived_category_count,
          actived_category_use_rate:this.totalData.avg_actived_category_use_rate,
          site_level_a_count:this.totalData.total_site_level_a_count,
          site_level_b_count:this.totalData.total_site_level_b_count,
          site_level_c_count:this.totalData.total_site_level_c_count,
          site_level_d_count:this.totalData.total_site_level_d_count,
          area_info:{dingding_qrcode_expire:null}
        }
        this.data.unshift(obj);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeDate(data){
      this.date = data;
      this.page = 1;
      this.getList();
    },
    selectUser(){
      this.page = 1;
      this.getList();
    },
    getCustomerList(){
      this.api.customer.serviceList().then((res)=>{
        this.customerUserList = res.list;
      })
    },
    getPlaceList(){ //获取地域
      this.api.site.placeList().then((res)=>{
        this.cityList = res.list;
      })
    },
    showModal(){
      this.modal = true;
      this.modalCityList = JSON.parse(JSON.stringify(this.cityList));
      this.modalCityList.forEach((item)=>{
        this.$set(item,'place_id',item.id);
        let setData = this.data.filter((sItem)=>{
          return sItem.id == item.id
        });
        let userId = '';
        if(setData.length){
          userId = setData[0].user.id;
        }
        this.$set(item,'customer_service_user_id',userId);
      })

    },
    closeModal(){
      this.modal = false;
    },
    confirmSet(){
      let data = this.modalCityList.filter((item)=>{
        return item.customer_service_user_id;
      });
      let params = {
        data:data
      }
      this.api.customer.batchSetArea(params).then((res)=>{
        this.$Message.success('操作成功');
        this.modal = false;
        this.page = 1;
        this.getList();
      })
    },
    goUser(){
      this.$router.push({
        path:'/customer_service/user'
      })
    },
    goDetail(data){
      this.$router.push({
        path:'/site',
        query:{
          placeId:data.id
        }
      })
    },
    addDingGroup(data){
      this.curData = data;
      this.addDingGroupModal = true;
      this.uploadImg = data.area_info.dingding_qrcode;
      this.formValidate.uploadfileId = '';
      this.formValidate.num = data.area_info.dingding_number;
      this.formValidate.date = data.area_info.dingding_qrcode_expire >0 ?util.timeFormatter(
          new Date(+data.area_info.dingding_qrcode_expire * 1000),
          'yyyy-MM-dd'
      ):'';
      this.$nextTick(()=>{
        this.$refs['formValidate'].clearValidate('date');
      })
    },
    uploadFileSuccess(response,file, fileList){  //上传文件成功
      this.formValidate.uploadfileId = response.data.info.id;
      this.uploadImg = response.data.info.upload_path;
      this.$refs['formValidate'].clearValidate('uploadfileId')
    },
    closeAddDingModal(){
      this.addDingGroupModal = false;
    },
    confirmAddDing(){
      this.$refs['formValidate'].validate((valid) => {
        if (valid) {
          let params = {
            place_id:this.curData.id,
            dingding_number:this.formValidate.num,
            dingding_qrcode_uploadid:this.formValidate.uploadfileId,
            dingding_qrcode_expire:new Date(this.formValidate.date).getTime()/1000
          };

          this.api.customer.areaUpdate(params).then((res)=>{
            this.$Message.success('操作成功');
            this.addDingGroupModal = false;
            this.page = 1;
            this.getList();
          })
        }
      })
    },
    exportTeacher(row){
      console.log(row);
      this.api.user.exportTeacher({
        province_id:row.place.id,
        site_id:"-1"
      }).then(res=>{
        this.api.codekey.uploadfileDownload({
          file:res.filepath
        }).then((res)=>{
          util.downLoad(res);
        });
      });
    },
    followUp(row){
      console.log(row);
      this.followupShow=true;
    },
    followUpSave(){
      const { followForm }=this;
      this.api.customer.createFollowUp(followForm).then(res=>{
        console.log("跟进成功",res);
      });
    },
    // 区域跟进点击
    regionalFollowUp(){
      this.areaFollowShow=true;
    },
    areaFollowClose(){
      this.provinceId="";
      this.areaFollowShow=false;
    }
  }
}
</script>

<style scoped lang="scss">

::v-deep .ivu-table .row1Style td{
  background-color: #3e9aec4b;
}
.service{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;

  .search{
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    >div{
      margin-right: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .label{
      font-weight: bold;
    }
    .cont{
      display: flex;
      justify-content: flex-start;
      >p{
        margin-right: 20px;
        cursor: pointer;
      }
      .active{
        font-weight: bold;
        color: #4A79FF;
      }
    }
  }

}
.set-area-user{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 14px;
  .set-area-user-item{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .set-area-user-item-name{
      margin-right: 10px;
      width: 120px;
      text-align: right;
    }
  }
}
.upload-btn{
  width: 120px;
  height: 120px;
  border: 1px solid #EEEEEE;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .ivu-table .ivu-table-tbody{
  background-color: white;
}

.form-title{
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

</style>
