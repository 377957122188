<template>
  <div>
    <el-drawer :visible.sync='drawerShow' @close="drawerColse()" :size="1200">
      <div class='drawer-title' slot='title'>
        <div class='drawer-title-text'>
          <div class="area-select">
            <div>区域跟进</div>
            <el-select v-model="searchForm.province_id" @change="getBatchList()" size="small" placeholder="请选择区域">
              <el-option v-for="item in areaList" :key="item.place.id" :label="item.place.name" :value="item.place.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class='drawer-title-btns'>
          <el-button @click="batchManageDrawer = true" size="small" type="primary">跟进批次管理</el-button>
          <el-button @click="createFollowUpDrawerShow=true" size="small" type="primary">创建跟进批次</el-button>
        </div>
      </div>
      <div style='padding:20px;'>
        <div class="search-box">
          <div class=search-item>
            <p class="search-title">批次：</p>
            <div class="batch-list">
              <el-alert v-if="searchForm.province_id && batchList.length === 0" title="当前区域没有跟进批次，点击创建跟进批次去创建跟进批次"
                type="warning" close-text="去创建" @close="createFollowUpBatches()">
              </el-alert>
              <template v-else>
                <div class="batch-item" :class="item.id === searchForm.followup_batch_id ? 'batch-active' : ''"
                  v-for="item in batchList" :key="item.id" @click="changeBatch(item.id)">{{ item.name }}</div>
              </template>
            </div>
          </div>
          <div class=search-item>
            <p class="search-title">状态：</p>
            <div class="search-content" :class="item.id === searchForm.status ? 'content-active' : ''" v-for="item in statusesList" :key="item.id" @click="changeStatus(item.id)">{{ item.name }}</div>
          </div>
        </div>
        <el-table style="margin-top: 20px;" size="small" :data="teacherList" :header-cell-style="headerCellStyle" border>
          <el-table-column label="用户ID" prop="user.id" width="80"></el-table-column>
          <el-table-column label="姓名" show-overflow-tooltip>
            <template v-slot='{ row }'>
              {{ row?.user?.realname || row?.user?.nickname }}
            </template>
          </el-table-column>
          <el-table-column label="手机号" width="120" prop="user.mobile"></el-table-column>
          <el-table-column label="状态" width="150">
            <template v-slot='{row}'>
              <el-select v-model="row.status" size="mini" @change="updateFollowStatus($event,row.id)">
                <el-option v-for="s in statusesList.filter(e=>e.id!=='-1')" :key="s.id" :label="s.name" :value="s.id"></el-option>
              </el-select> 
            </template>
          </el-table-column>
          <el-table-column label="跟进内容" prop="followup.content"></el-table-column>
          <el-table-column label="跟进时间" width="150">
            <template v-slot='{row}'>
              {{ util.timeFormatter(new Date(+row.created_at * 1000),'yyyy-MM-dd hh:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template v-slot='{ row }'>
              <el-button type="primary" size="mini" @click="followUp(row)">跟进</el-button>
            </template>
          </el-table-column>
        </el-table>
        <MyPagin :pagination="pagination" @currentChange="currentChange" @sizeChange="sizeChange"></MyPagin>
      </div>
    </el-drawer>
    <el-drawer :visible.sync='batchManageDrawer' :size="800">
      <div class='drawer-title' slot='title'>
        <div class='drawer-title-text'>管理跟进</div>
        <div class='drawer-title-btns'></div>
      </div>
      <div style='padding:20px;'>
        <el-table size="small" border :data="batchList" :header-cell-style="headerCellStyle">
          <el-table-column label="ID" prop="id" width="80"></el-table-column>
          <el-table-column label="批次名称" prop="name"></el-table-column>
          <el-table-column label="操作" width="80">
            <template v-slot='{ row }'>
              <el-button type="danger" size="mini" @click="deleteBatch(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
    <el-drawer :visible.sync='followDrawer' :size="800" @close="followDrawerClose()">
      <div class='drawer-title' slot='title'>
        <div class='drawer-title-text'>跟进</div>
        <div class='drawer-title-btns'></div>
      </div>
      <div style='padding:20px;'>
        <el-form ref="formRef" :model="followForm" label-width="80px">
          <el-form-item label="跟进内容">
            <el-input type="textarea" v-model="followForm.content" :rows="3" placeholder="请输入跟进备注"></el-input>
          </el-form-item>
          <el-form-item style="text-align: end;">
            <el-button type="primary" size="small" @click="createFollowUp()">确定</el-button>
          </el-form-item>
        </el-form>
        <Timeline class="timeline">
          <TimelineItem v-for="item in recordsList" :key="item.id">
            <p class="time">{{ item.created_at }} <span>|</span>跟进人：{{ item?.user?.nickname }}（{{ item?.user?.id
            }}）<span>|</span>创建人：{{ item?.creator?.nickname }}（{{ item.creator.id }}）</p>
            <div class="content-wrap">
              <p class="content">{{ item.content }}</p>
              <div>
                <el-button type="warning" size="mini" @click="editRecord(item)">编辑</el-button>
                <el-button type="danger" size="mini" @click="deleteRecord(item)">删除</el-button>
              </div>
            </div>
          </TimelineItem>
        </Timeline>
      </div>
    </el-drawer>
    <el-drawer :visible.sync='editDrawer' @close="editDrawerClose()">
      <div class='drawer-title' slot='title'>
        <div class='drawer-title-text'>编辑跟进记录</div>
        <div class='drawer-title-btns'></div>
      </div>
      <div style='padding:20px;'>
        <el-form ref="editFormRef" :model="editForm" label-width="80px">
          <el-form-item label="跟进内容">
            <el-input type="textarea" v-model="editForm.content" :rows="3" placeholder="请输入跟进备注"></el-input>
          </el-form-item>
          <el-form-item style="text-align: end;">
            <el-button type="primary" size="small" @click="updateFollowUp()">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <!-- 创建跟进批次 -->
    <el-drawer :visible.sync='createFollowUpDrawerShow' :size="800" @close="createFollowUpDrawerClose()">
      <div class='drawer-title' slot='title'>
        <div class='drawer-title-text'>创建跟进批次</div>
        <div class='drawer-title-btns'>
          <el-button size="small" type="primary" @click="createFollowUpBatches()">确定</el-button>
        </div>
      </div>
      <div style='padding:20px;'>
        <el-form :model="createFollowUpForm" ref="createRef" :rules="createRules" label-width="80px">
          <el-form-item label="名称" prop="name">
            <el-input type="textarea" v-model="createFollowUpForm.name" placeholder="请输入跟进批次名称"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, watch } from 'vue';
import { MessageBox } from "element-ui";
import util from "@/utils/tools.js";

// 组件实例（相当于this）
const instance = getCurrentInstance().proxy;
// 定义props
const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  provinceId:{
    type:String,
    default:""
  }
});
// 定义emit
const emit = defineEmits(["close"]);

// 弹窗显示关闭相关逻辑
const drawerShow = ref(false);
watch(() => props.show, (newVal) => {
  if (newVal) {
    getAreaList();
  }
  drawerShow.value = newVal;
}, { immediate: true });
function drawerColse() {
  searchForm.followup_batch_id="";
  searchForm.province_id="";
  emit("close");
}

const searchForm = reactive({
  // 区域ID
  province_id: "",
  // 批次id
  followup_batch_id: "",
  // 状态
  status:"-1"
});

// 区域列表数据
const areaList = ref([]);
// 获取区域列表数据
function getAreaList() {
  instance.api.customer.areaList({
    user_id: "-1",
    page_size:"99"
  }).then(res => {
    console.log("区域列表数据", res);
    areaList.value = res.list || [];
    if (areaList.value.length > 0) {
      if(props.provinceId){
        searchForm.province_id=props.provinceId;
      }else{
        searchForm.province_id = areaList.value[0].place.id;
      }
      // 拿到区域id再获取批次数据
      getBatchList();
    } else {
      batchList.value = [];
      MessageBox({
        title: "提示",
        message: "当前暂无区域数据",
        type: 'warning'
      }).then(() => {
        emit("close");
      });
    }
  });
}


// 批次列表数据
const batchList = ref([]);
// 获取批次列表
function getBatchList() {
  instance.api.customer.createFollowBatchList({
    site_id: "-1",
    province_id: searchForm.province_id
  }).then(res => {
    batchList.value = res.list || [];
    console.log("批次列表", res);
    if (batchList.value.length > 0) {
      searchForm.followup_batch_id = batchList.value[0].id;
      // 拿到批次id再获取老师列表
      getBatchTeacherList();
    } else {
      teacherList.value = [];
    }
  });
}

// 切换批次
function changeBatch(id) {
  searchForm.followup_batch_id = id;
  getBatchTeacherList();
}


const createFollowUpDrawerShow=ref(false);
const createRules={
  name:[
    {required:true,message:"请输入批次名称",trigger:"blur"}
  ]
}
function createFollowUpDrawerClose(){
  createFollowUpForm.name="";
  createRef.value.resetFields();
}
const createRef=ref(null);
const createFollowUpForm=reactive({
  name:""
});
// 创建跟进批次
function createFollowUpBatches() {                                                                                                                                          
  createFollowUpDrawerShow.value=true;
  createRef.value.validate(valid=>{
    if(valid){
      instance.api.customer.customerFollowBatchCreate({
        province_id: searchForm.province_id,
        ...createFollowUpForm
      }).then(() => {
        createFollowUpDrawerShow.value=false;
        instance.$Message.success("批次创建成功");
        getBatchList();
      });
    }
  });
}
// 删除批次
function deleteBatch(row) {
  MessageBox.confirm('确定删除当前批次吗？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    instance.api.customer.deleteBatch({
      id: row.id
    }).then(() => {
      instance.$Message.success("删除批次成功");
      getBatchList();
    });
  });
}

// 表头样式
const headerCellStyle = {
  backgroundColor: "#F8F8F9",
  color: "#666",
  fontWeight: "bold"
}
// 教师列表
const teacherList = ref([]);
// 教师状态对象
const statusesObj = ref({});
// 教师状态列表
const statusesList = ref([]);
// 获取教师列表
function getBatchTeacherList() {
  instance.api.customer.createFollowItemList({
    ...searchForm,
    page: pagination.page,
    page_size: pagination.pageSize
  }).then(res => {
    console.log("跟进批次老师列表", res);
    pagination.total = Number(res.count);
    statusesObj.value = res.statuses || {};
    const arr=[];
    for(let key in res.statuses){
      arr.push({
        id:key,
        name:res.statuses[key]
      });
    }
    arr.unshift({
      id:"-1",
      name:"全部"
    });
    statusesList.value=arr;
    teacherList.value = res.list || [];
  });
}

// 改变状态
function changeStatus(id){
  searchForm.status=id;
  getBatchTeacherList();
}


// 分页器数据
const pagination = reactive({
  page: 1,
  pageSize: 10,
  total: 0
});
function currentChange(page) {
  pagination.page = page;
  getBatchTeacherList();
}
function sizeChange(size) {
  pagination.pageSize = size;
  getBatchTeacherList();
}


// 跟进表单
const followForm = reactive({
  // 组织id
  followup_site_id: props.organizationId,
  // 用户id
  followup_user_id: "",
  content: "",
  followup_batch_id: "",
  followup_item_id: ""
});
// 跟进抽屉可见性
const followDrawer = ref(false);
function followDrawerClose() {
  getBatchTeacherList();
}
// 跟进
function followUp(row) {
  followForm.followup_user_id = row.user.id;
  followForm.followup_batch_id = row.followup_batch_id;
  followForm.followup_item_id = row.id;
  followDrawer.value = true;
  getRecordsList();
}

const recordsList = ref([]);
// 获取跟进记录列表
function getRecordsList() {
  instance.api.customer.customerFollowList(followForm).then(res => {
    recordsList.value = res.list || [];
  });
}
// 创建跟进记录
function createFollowUp() {
  if (!followForm.content) {
    instance.$Message.error("请输入跟进内容");
    return;
  }
  instance.api.customer.customerFollowCreate(followForm).then(() => {
    instance.$Message.success("跟进成功");
    followForm.content = "";
    getRecordsList();
  });
}
const editForm = reactive({
  // 组织id
  id: "",
  content: "",
  // 用户id
  followup_user_id: ""
});

// 更新跟进状态
function updateFollowStatus(status,id){
  instance.api.customer.updateFollowStatus({
    status,
    id
  });
}

// 编辑跟进记录
function editRecord(item) {
  console.log(item);
  editForm.id = item.id;
  editForm.followup_user_id = item.user_id;
  editDrawer.value = true;
}
// 更新
function updateFollowUp() {
  if (!editForm.content) {
    instance.$Message.error("请输入跟进内容");
    return;
  }
  instance.api.user.updateFollow(editForm).then(() => {
    editDrawer.value = false;
    getRecordsList();
    instance.$Message.success("编辑成功");
  });
}
// 删除跟进记录
function deleteRecord(item) {
  MessageBox.confirm('该操作不可逆是否继续', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    instance.api.user.deleteFollow({
      id: item.id
    }).then(() => {
      getRecordsList();
      instance.$Message.success("删除成功");
    });
  });
}

// 编辑跟进抽屉可见性
const editDrawer = ref(false);
function editDrawerClose() {
  editForm.id = "";
  editForm.content = "";
  editForm.followup_user_id = "";
}

// 管理跟进抽屉课件性
const batchManageDrawer = ref(false);


</script>

<style lang="scss" scoped>
.area-select {
  display: flex;
  align-items: center;

  .el-select {
    margin-left: 10px;
  }
}

.search-box {
  .search-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 20px;

    .search-title {
      font-weight: bold;
      flex-shrink: 0;
    }
    .search-content{
      font-size: 16px;
      margin-right: 10px;
      cursor: pointer;
      padding: 3px 5px;
    }
    .content-active {
      background-color: #409EFF;
      color: white;
      border-radius: 4px;
      padding: 3px 5px;
    }

    .batch-list {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;

      .batch-item {
        font-size: 16px;
        margin-right: 10px;
        cursor: pointer;
        padding: 3px 5px;
      }

      .batch-active {
        background-color: #409EFF;
        color: white;
        border-radius: 4px;
        padding: 3px 5px;
      }
    }
  }
}

.timeline {
  font-size: 14px;

  .time {
    font-size: 14px;
    margin-bottom: 10px;

    >span {
      padding: 0 20px;
    }
  }

  .content-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content {
      font-size: 14px;
      line-height: 26px;
      white-space: pre-wrap;
    }
  }
}
</style>